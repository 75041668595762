import React, { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Nav.css";
import logo from "../assets/RenuLogo.png";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function Nav() {
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const handleNavLinkClick = () => {
    if (isNavbarOpen) {
      toggleNavbar();
    }
  };

  const smoothScroll = (targetId) => {
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // Handle click on navigation links
  const handleNavClick = (event, targetId) => {
    event.preventDefault();
    smoothScroll(targetId);
  };

  useEffect(() => {
    // Clean-up effect
    return () => {};
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="#">
        <div className="d-flex align-items-center">
          <img src={logo} alt="Logo" className="d-inline-block align-top" />
          <span className="company-logo">RENU-BUILD</span>
        </div>
      </a>
      <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse ${
          isNavbarOpen ? "show slide-in" : ""
        } justify-content-center`}
      >
        <ul className="navbar-nav" onClick={handleNavLinkClick}>
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={(e) => handleNavClick(e, "packages")}>
              Homeowners
            </a>
          </li>
          <li className="nav-item" >
            <a className="nav-link" href="#" onClick={(e) => handleNavClick(e, "Businesses")}>
              Businesses
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={(e) => handleNavClick(e, "Company")} >
              Company
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="#">
              News
            </a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={(e) => handleNavClick(e, "Contact")}>
              Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
