import React, { useState,useEffect } from "react";
import Nav from "../components/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import Typewriter from "typewriter-effect";
import Package from "../components/Packages";
import SAFlag from "../assets/SA RenuBuild.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import ReferralProgram from "../components/RefferalProgram";
import { sendEmail } from "../utils/sendEmail";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const images = [require("../assets/landing_img.jpg")];

function Home() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await sendEmail(formData);
      setSuccess("Message sent successfully!");
      setFormData({ name: "", email: "", contactNumber: "", message: "" });
    } catch (error) {
      setError("Failed to send message. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // Tawk.to script
  //   var Tawk_API = Tawk_API || {},
  //     Tawk_LoadStart = new Date();
  //   (function () {
  //     var s1 = document.createElement("script"),
  //       s0 = document.getElementsByTagName("script")[0];
  //     s1.async = true;
  //     s1.src = "https://embed.tawk.to/6665bdea981b6c56477b4e91/1hvumrntj";
  //     s1.charset = "UTF-8";
  //     s1.setAttribute("crossorigin", "*");
  //     s0.parentNode.insertBefore(s1, s0);
  //   })();
  // }, []);
  const smoothScroll = (targetId) => {
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // Handle click on navigation links
  const handleNavClick = (event, targetId) => {
    event.preventDefault();
    smoothScroll(targetId);
  };

  useEffect(() => {
    // Clean-up effect
    return () => {};
  }, []);

  return (
    <div>
      <Nav />

      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner landing-container">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <div className="overlay"></div>
              <img
                className="d-block w-100 img-landing"
                src={image}
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </div>
        <div className="carousel-caption d-flex flex-column justify-content-center align-items-center h-100">
          <h1 className="text-nowrap mb-5">The Power of Solar Energy</h1>
          <Typewriter
            className="typeText"
            options={{
              strings: [
                "Eco-friendly solar installations that preserve your landscape.",
                "Empowering businesses with sustainable energy solutions.",
                "Bringing solar energy solutions to every corner of South Africa.",
                "High-performance solar systems for industrial needs.",
                "Customized solar systems for your home.",
                "Factory & Warehousing Solar specialist",
                "Enjoy uninterrupted power supply with solar energy.",
                "Customized solar systems for your home.",
              ],
              autoStart: true,
              loop: true,
            }}
          />
          <div className="text-center mt-4">
            <button className="btn sliding-button" onClick={(e) => handleNavClick(e, "packages")}>GET STARTED</button>
          </div>
        </div>
      </div>
      <div id="packages">
      <Package />
      </div>

      <ReferralProgram />

      <section className="py-5 bg-light" id="Businesses">
        <div className="container">
          <div className="row justify-content-center text-center mb-4">
            <div className="col-lg-8">
              <h1 className="mb-4">Why Choose Us for Your Solar Solutions</h1>
              <p className="lead">
                With years of experience in the solar industry, our team of
                certified professionals has the expertise to deliver top-notch
                solar installations tailored to your unique requirements. We
                stay ahead of the curve with the latest technological
                advancements, ensuring you receive the most efficient and
                reliable solar systems.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 position-relative">
              <img
                src={SAFlag}
                className="img-fluid rounded shadow"
                alt="South Africa Flag"
              />
              <div className="position-absolute top-50 start-50 translate-middle bg-dark text-white p-3 rounded shadow d-flex align-items-center">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
                We Deliver Service Everywhere in South Africa
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-dark text-white" id="Company">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <h2 className="mb-4">We Support You 24/7, Every Day</h2>
              <p className="lead">
                At RENU BUILD SOLUTION, we understand that reliable support is
                essential for your peace of mind. That's why our dedicated team
                is available around the clock, every single day, to address your
                queries, resolve issues, and ensure your solar experience is
                seamless.
              </p>
              <p>
                Whether it's a question about your solar system, assistance with
                maintenance, or troubleshooting an unexpected problem, our
                knowledgeable support staff is just a call or click away. We're
                here to support you at any hour, because your satisfaction is
                our priority.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light" id="Contact">
        <div className="container">
          <div className="row justify-content-center text-center mb-4">
            <div className="col-lg-8">
              <h2 className="mb-4">Get in Touch with Us</h2>
              <p className="lead">
                We’re here to help and answer any question you might have. We
                look forward to hearing from you.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="contactNumber" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="contactNumber"
                    placeholder="Your Number"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="4"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send Message"}
                </button>
                {error && <p className="text-danger mt-3">{error}</p>}
                {success && <p className="text-success mt-3">{success}</p>}
              </form>
            </div>
          </div>
          <div className="row justify-content-center text-center mt-5">
            <div className="col-lg-8">
              <h4>Contact Information</h4>
              <p>
                <strong>Email:</strong> support@renubuildsa.com
                <br />
              
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <a
        href="https://wa.me/27692820763"
        className="whatsapp-float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </a>
    </div>
  );
}

export default Home;
