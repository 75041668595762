import React from "react";

const referralData = [
  { capacity: "3 kW", reward: "R1500" },
  { capacity: "5 kW", reward: "R2500" },
  { capacity: "Larger Systems", reward: "R5000" },
];

const ReferralProgram = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Solar System Referral Program</h1>
      <p className="text-center mb-5">
        Enjoy top-tier solar installations and earn rewards for successful
        referrals. Join us in promoting clean energy and benefit from our
        exceptional service and support.
      </p>
      <div className="card-deck">
        {referralData.map((referral, index) => (
          <div className="card text-center" key={index}>
            <div className="card-body">
              <h5 className="card-title">
                Refer a {referral.capacity} Solar System
              </h5>
              <p className="card-text">
                Get <b>{referral.reward}</b> for
                Successful Installation
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReferralProgram;
