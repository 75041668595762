import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Button,
  ButtonGroup,
  Accordion,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Packages.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faTv,
  faUtensils,
  faThermometer,
  faTint,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";

function Package() {
  const [selectedType, setSelectedType] = useState("batteryAndSolar");

  const packages = {
    batteryAndSolar: [
      {
        title: "3Kw",
        description: "If your electricity spend is R1400pm - R1800pm",
        price: "1 369",
        subscription: "1 699",
        cashprice: "34 000",
        subtitle: "Your mid-sized solar solution",
        hours: "2 - 4 Hours",
        componets: [
          "3kw solarwise inverter ",
          "5 star Battery",
          "10 Year warranty",
        ],
        appliances: [
         
          "Router",
          "TV",
          "Lights",
         
          "Laptop",
          "Phone Charger",
        ],
      },
      {
        title: "5kw",
        description: "If your electricity spend is R1400pm - R1800pm",
        price: "1 369",
        subscription: "1 699",
        cashprice: "44 000",
        subtitle: "Your mid-sized solar solution",
        hours: "2 - 4 Hours",
        componets: [
          "5kw solarwise battery ",
          "5 star Battery",
          "10 Year warranty",
        ],
        appliances: [
         
          "Router",
          "TV",
          "Lights",
       
          "Laptop",
          "Phone Charger",
        ],
      },
      {
        title: "8kw Deye",
        description: "If your electricity spend is R1400pm - R1800pm",
        price: "1 369",
        subscription: "1 699",
        cashprice: "100 000",
        subtitle: "Your mid-sized solar solution",
        hours: "2 - 4 Hours",
        componets: ["1x Battery ", "8x  Solar Panels", "10 Year warranty"],
        appliances: [
          "Fridge",
          "Router",
          "TV",
          "Lights",
          "Microwave",
          "Laptop",
          "Phone Charger",
        ],
      },
      {
        title: "8kw Deye",
        description: "If your electricity spend is R800pm - R1200pm",
        price: "1350",
        subscription: "999",
        cashprice: "127 000",
        subtitle: "Your starter backup solution",
        hours: "2 - 4 Hours",
        componets: ["2x Bettery Deye", "8x Solar Panels", "10 Year warranty"],
        appliances: [
          "Fridge",
          "Stove",
          "Router",
          "TV",
          "Geyser",
          "Lights",
          "Microwave",
          "Laptop",
          "Phone Charger",
        ],
      },

      {
        title: "8kw Sunysynk",
        description: "If your electricity spend is R2000pm - R2800pm",
        price: "2 049",
        subscription: "2 850",
        cashprice: "137 500",
        subtitle: "Solar by day, battery by night",
        hours: "5 - 7 Hours",
        componets: [
          "Inverter 8kW Sunsynk",
          "Battery 2 x Sunsynk",
          "8x Solar Panels ",
          "10 Year warranty",
        ],
        appliances: [
          "Router",
          "TV",
          "Fridge",
          "Lights",
          "Microwave",
          "Laptop",
          "Phone Charger",
        ],
      },
    ],
    batteryOnly: [
      {
        title: "8kw Deye",
        description: "If your electricity spend is R800pm - R1300pm",
        price: "699",
        subscription: "1350",
        cashprice: "100 000",
        subtitle: "Your starter backup solution",
        hours: "2 - 3 Hours",
        componets: ["Battery 1 x Sunsynk 3.8kWh"],
        appliances: [
          "Fridge",
          "Router",
          "TV",
          "Lights",
          "Microwave",
          "Laptop",
          "Phone Charger",
        ],
      },
      {
        title: "8kw Deye",
        description: "If your electricity spend is R1400pm - R1800pm",
        price: "949",
        subscription: "1699",
        cashprice: "127 000",
        subtitle: "Your mid-sized solar solution",
        hours: "2 - 4 Hours",
        componets: ["Inverter 1 x Sunsynk 5kWh", "Battery 1 x Sunsynk 5kWh"],
        appliances: [
          "Fridge",
          "Router",
          "TV",
          "Lights",
          "Microwave",
          "Laptop",
          "Phone Charger",
        ],
      },
      {
        title: "8kw Sunysynk",
        description: "If your electricity spend is R1400pm - R1800pm",
        price: "949",
        subscription: "1699",
        cashprice: "137 500",
        subtitle: "Solar by day, battery by night",
        hours: "2 - 4 Hours",
        componets: ["Inverter 1 x Sunsynk 5kWh", "Battery 1 x Sunsynk 5kWh"],
        appliances: [
          "Fridge",
          "Router",
          "TV",
          "Lights",
          "Microwave",
          "Laptop",
          "Phone Charger",
        ],
      },
    ],
  };

  const applianceIcons = {
    fridge: faThermometer,
    stove: faUtensils,
    router: faPlug,
    tv: faTv,
    geyser: faTint,
    microwave: faUtensils,
  };

  const progressValues = [25, 50, 75, 100];

  return (
    <Container>
      <header className="text-center my-5">
        <h1>Our Packages</h1>
        <p>Choose the best package that suits your needs</p>

        <ButtonGroup className="custom-button-group mb-4">
          <Button
            className="custom-button"
            onClick={() => setSelectedType("batteryAndSolar")}
            active={selectedType === "batteryAndSolar"}
          >
            BATTERY AND SOLAR
          </Button>
          <Button
            className="custom-button"
            onClick={() => setSelectedType("batteryOnly")}
            active={selectedType === "batteryOnly"}
          >
            BATTERY ONLY
          </Button>
        </ButtonGroup>
      </header>

      <div className="d-block d-md-none package-carousel">
        <Carousel>
          {packages[selectedType].map((pkg, index) => (
            <Carousel.Item key={index}>
              <Card bg="dark" text="white" className="mb-4">
                <div className="progress-circle-container">
                  <svg className="progress-circle" viewBox="0 0 36 36">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                         a 15.9155 15.9155 0 0 1 0 31.831
                         a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${progressValues[index]}, 100`}
                      d="M18 2.0845
                         a 15.9155 15.9155 0 0 1 0 31.831
                         a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                  <div className="icon-circle">
                    <FontAwesomeIcon icon={faBolt} />
                  </div>
                </div>
                <Card.Body>
                  <h1 className="text-uppercase text-center">{pkg.title}</h1>
                  <Card.Text>{pkg.subtitle}</Card.Text>
                  <div className="backup-hours text-center">
                    <h1>Backup Hours</h1>
                    <h2>{pkg.hours}</h2>
                  </div>
                  <br />
                  <Card.Text>{pkg.description}</Card.Text>
                  <hr></hr>
                  {/* <Card.Text>
                    <strong>Subscription </strong>
                    <br />
                    <h2 className="price-color">
                      R{pkg.price} <small>p/m</small>
                    </h2>
                  </Card.Text>

                  <Card.Text>
                    <strong>Home loan</strong> <br />
                    <h2 className="price-color">
                      R{pkg.subscription} <small>p/m</small>
                    </h2>
                  </Card.Text> */}

                  <Card.Text>
                    <strong>Cash price</strong> <br />
                    <h2 className="price-color">R{pkg.cashprice}</h2>
                  </Card.Text>

                  <hr></hr>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="bg-dark text-white ">
                        What you can power
                      </Accordion.Header>
                      <Accordion.Body className="bg-dark text-white">
                        <ul className="appliance-list">
                          {pkg.appliances.map((appliance, idx) => (
                            <li key={idx}>
                              <FontAwesomeIcon
                                icon={applianceIcons[appliance]}
                              />{" "}
                              <span className="appliance-txt">
                                {" "}
                                {appliance}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <hr></hr>
                  <h3>Core components</h3>
                  {pkg.componets.map((components, idx) => (
                    <li key={idx}>
                      <span> {components}</span>
                    </li>
                  ))}
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      {/**The battery solution this side */}
      <div className="d-none d-md-block">
        <Row>
          {packages[selectedType].map((pkg, index) => (
            <Col key={index} xs={12} sm={6} md={3} className="mb-4">
              <Card bg="dark" text="white">
                <div className="progress-circle-container">
                  <svg className="progress-circle" viewBox="0 0 36 36">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                         a 15.9155 15.9155 0 0 1 0 31.831
                         a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${progressValues[index]}, 100`}
                      d="M18 2.0845
                         a 15.9155 15.9155 0 0 1 0 31.831
                         a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                  <div className="icon-circle">
                    <FontAwesomeIcon icon={faBolt} />
                  </div>
                </div>
                <Card.Body>
                  <h1>{pkg.title}</h1>
                  <Card.Text>{pkg.subtitle}</Card.Text>
                  <div className="backup-hours text-center">
                    <h3>Backup Hours</h3>
                    <h4>{pkg.hours}</h4>
                  </div>
                  <br />
                  <Card.Text>{pkg.description}</Card.Text>
                  <hr></hr>

                  {/* <Card.Text>
                    <strong>Subscription</strong> <br />
                    <h2 className="price-color">
                      R{pkg.price} <small>p/m</small>
                    </h2>
                  </Card.Text>

                  <Card.Text>
                    <strong> Home loan</strong> <br />
                    <h2 className="price-color">
                      R{pkg.subscription} <small>p/m</small>
                    </h2>
                  </Card.Text> */}

                  <Card.Text>
                    <strong>Cash price </strong> <br />
                    <h2 className="price-color">R{pkg.cashprice}</h2>
                  </Card.Text>

                  <hr></hr>
                  <Accordion bg="dark" text="white">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="bg-dark text-white">
                        <strong>What you can power</strong>
                      </Accordion.Header>
                      <Accordion.Body className="bg-dark text-white">
                        <ul className="appliance-list">
                          {pkg.appliances.map((appliance, idx) => (
                            <li key={idx}>
                              <FontAwesomeIcon
                                icon={applianceIcons[appliance]}
                              />{" "}
                              <span className="appliance-txt">
                                {" "}
                                {appliance}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <br />

                  <hr></hr>
                  <h3>Core components</h3>
                  {pkg.componets.map((components, idx) => (
                    <li key={idx}>
                      <span> {components}</span>
                    </li>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default Package;
